
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useLoginHook } from 'views/login/hooks';
import dynamic from 'next/dynamic';
import { MainLayout } from 'layouts';
import { WithRoleAuth, withAuthentication } from 'hocs';
import { ColorTheme } from 'configuration/ui';
const DashboardView = dynamic(() => import('views/dashboard/dashboard.view').then((c) => c.DashboardView), {
    ssr: false,
});
const DashboardCoachView = dynamic(() => import('views/dashboard/dashboard-coach.view').then((c) => c.DashboardCoachView), { ssr: false });
const DashboardAdminView = dynamic(() => import('views/dashboard/dashboard-admin.view').then((c) => c.DashboardAdminView), { ssr: false });
async function getStaticProps() {
    return {
        props: {},
    };
}
export const HomePage = () => {
    const { isCustomer, isCoach } = useLoginHook();
    return (<MainLayout pageTitle="Dashboard" pageName="dashboard" pageColor={isCustomer ? ColorTheme.mainLayoutColor : 'white'}>
      <WithRoleAuth permissionKey="home.read">
        {isCustomer ? <DashboardView /> : isCoach ? <DashboardCoachView /> : <DashboardAdminView />}
      </WithRoleAuth>
    </MainLayout>);
};
export default withAuthentication(HomePage);

    async function __Next_Translate__getStaticProps__1944f9c4051__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1944f9c4051__ as getStaticProps }
  