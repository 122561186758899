import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';

import styles from './tag.module.scss';

enum TagVariant {
  solid = 'solid',
  outline = 'outline',
  mixed = 'mixed',
}

enum TagSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum TagColor {
  default = 'default',
  blue = 'blue',
  contrast = 'contrast',
  green = 'green',
  red = 'red',
  purple = 'purple',
  orange = 'orange',
}

interface TagProps {
  size?: Lowercase<keyof typeof TagSize>;
  variant?: Lowercase<keyof typeof TagVariant>;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  color?: Lowercase<keyof typeof TagColor>;
  children?: ReactNode;
  title?: string;
  onClick?: () => void;
}

export const Tag = forwardRef<HTMLSpanElement, TagProps>(
  (
    {
      size = TagSize.md,
      variant = TagVariant.solid,
      iconLeft,
      iconRight,
      color = TagColor.default,
      children,
      title,
      onClick,
    },
    ref,
  ) => {
    return (
      <span
        ref={ref}
        title={title}
        className={clsx(
          styles.root,
          styles[`variant-${variant}`],
          onClick && styles.btn,
          {
            [styles.sm]: size === TagSize.sm,
            [styles.lg]: size === TagSize.lg,
            [styles.xs]: size === TagSize.xs,
          },
          styles[`color-${color}`],
        )}
      >
        {iconLeft && <span>{iconLeft}</span>}
        <span>{children}</span>
        {iconRight && <span>{iconRight}</span>}
      </span>
    );
  },
);
