import { Appointment } from 'common/interfaces';
import { AppointmentParticipant } from 'common/interfaces';
import { AppointmentStatusEnum, UserTypeEnum } from 'enums';
import { captureException } from 'utils';
import { isSessionActive } from 'utils/is-session-active';

export const sessionFirstPipe = (appointments: Appointment[]) => appointments.map(parseSession);

export const parseSession = (a: Appointment) => {
  try {
    const customer = a.appointmentParticipants.data.find((f) => f.user?.type === UserTypeEnum.CUSTOMER);
    return {
      ...a,
      isActiveSession: a.start && a.status !== AppointmentStatusEnum.ENDED ? isSessionActive(a.start, a.end) : false,
      users: sessionUsers(a.appointmentParticipants.data),
      formalTitle: `${a.appointmentParticipants.data
        .filter((f) => f.user?.firstName)
        .map((u) => u.user.firstName)
        .join(' & ')} ${a.title}`,
      customerUser: {
        id: customer?.userId,
        fullName: customer?.externalName,
      },
    };
  } catch (error) {
    captureException(error);
    return null;
  }
};

const sessionUsers = (participants: AppointmentParticipant[]) => participants.map(({ user }) => ({ ...user }));
